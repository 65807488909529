import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Image } from '../../Images'
import { Link as GatsbyLink } from 'gatsby'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import RenderSocialLinks from './RenderSocialLinks'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBlock: '40px',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 1em',
    },
  },
  innerDiv: {
    justifyContent: 'space-between',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
    },
  },
  reverseOrderFirst: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  reverseOrderSecond: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  img: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },

  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    lineHeight: '80px',
    fontSize: '80px',
    fontWeight: 400,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(48),
    },
  },
  detail: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '32px',
    fontSize: '24px',
    fontWeight: 400,
    textAlign: 'left',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 500,
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      fontSize: '16px',
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  followUs: {
    fontFamily: `'Gotham Book', serif`,
    display: 'flex',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
  },
  rsBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      justifyContent: 'center',
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(13.8),
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(15.6),
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
  },
}))

interface OurHistoryProps {
  text: {
    title: string | React.ReactNode
    detail: string | React.ReactNode
    buttonText: string
    link: string
  }
  imageName: string
}

const OurHistory: React.FC<OurHistoryProps> = ({ text, imageName }) => {
  const classes = useStyles()
  const theme = useTheme()
  const isSm = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={isSm ? 0 : 10} className={classes.innerDiv}>
        <Grid item lg={6} md={12}>
          <Grid className={classes.title}>
            <span>{text.title}</span>
          </Grid>
          <Grid container spacing={isSm ? 0 : 3} className={classes.detail}>
            <Grid item md={12} lg={12} className={classes.detail}>
              {text.detail}
            </Grid>
          </Grid>
        </Grid>

        <Grid item lg={6} xs={12}>
          <Image
            src={imageName}
            className={classes.img}
            alt="Image"
            layout="CONSTRAINED"
            style={{ height: '100%', width: '100%', zIndex: 0, borderRadius: '8px' }}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default OurHistory
