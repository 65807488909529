import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  middleText: (props: { size: string }) => ({
    fontSize:
      props.size === 'xs'
        ? '12px'
        : props.size === 'sm'
        ? '14px'
        : props.size === 'md'
        ? '18px'
        : '32px',
    fontWeight: 700,
    textAlign: 'center',
    wordWrap: 'break-word',
    fontFamily: `'Gotham Medium', serif`,
    letterSpacing:
      props.size === 'xs'
        ? '0px'
        : props.size === 'sm'
        ? '0px'
        : props.size === 'md'
        ? '-2px'
        : '-2px',
  }),
}))

interface CircularTextProps {
  topText: string
  bottomText: string
  centerText: string
  size?: 'xs' | 'sm' | 'md' | 'lg' // Taille du cercle
  textColor?: string // Couleur du texte
  borderColor?: string // Couleur de la bordure
}

const CircularText: React.FC<CircularTextProps> = ({
  topText,
  bottomText,
  centerText,
  size = 'md', // Taille par défaut
  textColor = '#000', // Couleur noire par défaut
  borderColor = '#000', // Bordure noire par défaut
}) => {
  const radius = size === 'xs' ? 50 : size === 'sm' ? 75 : size === 'md' ? 100 : 100 // Taille du rayon du cercle
  const intl = useIntl()
  const classes = useStyles({ size })

  return (
    <div
      style={{
        position: 'relative',
        width: radius * 2,
        height: radius * 2,
        border: `2px solid ${borderColor}`,
        borderRadius: '50%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        background: 'transparent',
      }}
    >
      {/* Texte arrondi en haut */}
      <svg
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <path
          id="topTextPath"
          d={`M 10 ${radius} A ${radius - 10} ${radius - 10} 0 1 1 ${radius * 2 - 10} ${radius}`}
          fill="none"
        />
        <text
          fill={textColor}
          fontSize={
            size === 'xs' ? '8px' : size === 'sm' ? '10px' : size === 'md' ? '12px' : '16px'
          }
          textAnchor="middle"
          dy="10"
          fontFamily="Gotham Medium, serif"
        >
          <textPath href="#topTextPath" startOffset="50%">
            {topText}
          </textPath>
        </text>
      </svg>

      {/* Texte arrondi en bas */}
      <svg
        viewBox={`0 0 ${radius * 2} ${radius * 2}`}
        style={{
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          height: '100%',
        }}
      >
        <g transform={`rotate(180, ${radius}, ${radius})`}>
          <path
            id="bottomTextPath"
            d={`M ${radius * 2 - 10} ${radius} A ${radius - 10} ${radius - 10} 0 1 0 10 ${radius}`}
            fill="none"
          />
          <text
            fill={textColor}
            fontSize={
              size === 'xs' ? '8px' : size === 'sm' ? '10px' : size === 'md' ? '12px' : '16px'
            }
            textAnchor="middle"
            dy="0"
            fontFamily="Gotham Medium, serif"
          >
            <textPath href="#bottomTextPath" startOffset="50%">
              {bottomText}
            </textPath>
          </text>
        </g>
      </svg>

      {/* Texte central */}
      <span className={classes.middleText}>{centerText}</span>
    </div>
  )
}

export default CircularText
