import { Button, makeStyles } from '@material-ui/core'
import React from 'react'
import { CustomButton } from '../../CustomButton'
import KeyboardArrowRightIcon from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowLeftIcon from '@material-ui/icons/KeyboardArrowLeft'
import ArrowForwardIcon from '@material-ui/icons/ArrowForwardIos'
import ArrowBackIcon from '@material-ui/icons/ArrowBackIos'
import IconButton from '@material-ui/core/IconButton'
import CustomIconButton from '../../shared/components/CustomIconButton'

const useStyles = makeStyles((theme) => ({
  buttonGroupContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    position: 'absolute',
    width: '100%',
    marginBlock: '1rem',
    paddingTop: '1.5rem',
    paddingRight: '2rem',
    gap: '24px',
    bottom: '2.5rem',
    [theme.breakpoints.between('md', 'md')]: {
      paddingRight: '1rem',
    },
    [theme.breakpoints.down('sm')]: {
      paddingRight: '0rem',
      display: 'none',
    },
  },
  buttonGroupSide: {
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    pointerEvents: 'none',
  },
  prevWrapper: {
    position: 'absolute',
    left: '10%',
    transform: 'translateY(-450%)',
    pointerEvents: 'all',
  },
  nextWrapper: {
    position: 'absolute',
    right: '10%',
    transform: 'translateY(-450%)',
    pointerEvents: 'all',
  },
  verticalButtonGroup: {
    position: 'absolute',
    right: 0,
    top: '45%',
    transform: 'translateY(150%)',
    display: 'flex',
    flexDirection: 'column',
    gap: '1rem',
    paddingRight: '1rem',
    [theme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
}))

interface CustomButtonGroupProps {
  next?: () => void
  previous?: () => void
  sideButton?: boolean
  isVertical?: boolean
}

const CustomButtonGroupAsArrows: React.FC<CustomButtonGroupProps> = ({
  next,
  previous,
  sideButton = false,
  isVertical = false, // Par défaut à false
}) => {
  const classes = useStyles()

  if (isVertical) {
    // Si `isVertical` est vrai, affiche les boutons de manière verticale
    return (
      <div className={classes.verticalButtonGroup}>
        <CustomIconButton icon={<ArrowBackIcon />} onClick={previous} />
        <CustomIconButton icon={<ArrowForwardIcon />} position="right" onClick={next} />
      </div>
    )
  }

  // Affichage par défaut en mode horizontal
  return sideButton ? (
    <>
      <div className={classes.prevWrapper}>
        <CustomIconButton noBorder icon={<ArrowBackIcon />} onClick={previous} />
      </div>
      <div className={classes.nextWrapper}>
        <CustomIconButton noBorder icon={<ArrowForwardIcon />} position="right" onClick={next} />
      </div>
    </>
  ) : (
    <div className={classes.buttonGroupContainer}>
      <CustomIconButton icon={<ArrowBackIcon />} onClick={previous} />
      <CustomIconButton icon={<ArrowForwardIcon />} position="right" onClick={next} />
    </div>
  )
}

export default CustomButtonGroupAsArrows
