import React from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import TextImageComponent from './agency/TextImageComponent'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import videofile from '../../videos/seq01_white.mp4'

const useStyles = makeStyles((theme) => ({
  experties: {
    width: '100vw',
    justifyContent: 'center',
    paddingInline: '204px',
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingInline: '16px',
      paddingBlock: '80px 0',
    },
  },
  expertTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(80),
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(48),
    },
  },
  partnerContent: {
    margin: '2em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '1.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.8em 0',
    },
  },
}))

const Experts = () => {
  const intl = useIntl()
  const classes = useStyles()
  const theme = useTheme()

  const isMd = useMediaQuery(theme.breakpoints.down('md'))

  return (
    <Grid container xs={12} className={classes.experties}>
      <Grid className={classes.expertTitle}>{intl.formatMessage({ id: 'new.Agence_8' })}</Grid>
      <Grid container className={classes.partnerContent}>
        <TextImageComponent
          text={{
            title: intl.formatMessage({ id: 'new.Agence_9' }),
            detail: intl
              .formatMessage({ id: 'new.Agence_10' })
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>
                  {paragraph.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>{line}</React.Fragment>
                  ))}
                </p>
              )),
            buttonText: '',
            link: '',
          }}
          imageName="DSC00617.jpg"
          direction="ltr"
          showButton={false}
        />
        <TextImageComponent
          text={{
            title: intl.formatMessage({ id: 'new.Agence_11' }),
            detail: intl
              .formatMessage({ id: 'new.Agence_12' })
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>
                  {paragraph.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>{line}</React.Fragment>
                  ))}
                </p>
              )),
            buttonText: '',
            link: '',
          }}
          imageName="DSC00765.jpg"
          direction={isMd ? 'ltr' : 'rtl'}
          showButton={false}
        />
        <TextImageComponent
          text={{
            // title: intl.formatMessage({ id: 'agencyPage.expertise.part3.title' }),
            title: intl.formatMessage({ id: 'new.Agence_13' }),
            detail: intl
              .formatMessage({ id: 'new.Agence_14' })
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>
                  {paragraph.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>{line}</React.Fragment>
                  ))}
                </p>
              )),
            buttonText: '',
            link: '',
          }}
          imageName={videofile}
          type="video"
          isPhoneImage={true}
          direction="ltr"
          showButton={false}
          showSocialBtn={true}
        />
      </Grid>
    </Grid>
  )
}

export default Experts
