import { Hidden, Typography, useMediaQuery } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import { Link as GatsbyLink } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import React from 'react'
import { CustomButton } from '../../CustomButton'
import { Image } from '../../Images'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import CircularText from './CircularText'
import { useTheme } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    backgroundColor: '#E0E5DE',
    padding: '80px 204px',
    textAlign: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  agencyDetailsTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    lineHeight: '1',
    letterSpacing: '0em',
    fontSize: '80px',
    fontWeight: 400,
    paddingTop: '24px',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: '48px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      fontSize: '48px',
    },
  },
  sub_title: {
    fontFamily: `'Gotham book', serif`,
    fontSize: '14px',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(5),
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: '16px',
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '16px',
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  },
  detail: {
    fontFamily: `'Gotham book', serif`,
    fontWeight: 325,
    fontSize: theme.typography.pxToRem(14),
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(3),
    flexBasis: '80%',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(15),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(13),
    },
  },
  images: {
    paddingInline: '17px',
    [theme.breakpoints.between('xs', 'md')]: {
      paddingTop: '2rem',
      paddingInline: '0',
    },
  },
  topPadding: {
    paddingTop: '5rem',
    [theme.breakpoints.between('xs', 'md')]: {
      paddingTop: 0,
    },
  },
  imageOverlay: {
    position: 'relative',
    overflow: 'hidden',
    '&::after': {
      content: "''",
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: 'linear-gradient(to bottom, rgba(0, 0, 0, 0.0) 0%, rgba(0, 0, 0, 0) 80%)',
      pointerEvents: 'none',
      zIndex: 1,
    },
    '& img': {
      display: 'block',
      width: '100%',
      height: 'auto',
    },
  },

  episode: {
    paddingBottom: '4rem',
  },
  img: {},
  episodeTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(34),
    fontWeight: 'bold',
    padding: '1.3rem 0',
    textAlign: 'left',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(25),
      padding: '.8rem 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(20),
      padding: '.3rem 0',
    },
  },
  circularTextBoxContainer: {
    paddingTop: '32px',
    flexBasis: '70%',
    display: 'flex',
    justifyContent: 'center',
    gap: '3rem',
    [theme.breakpoints.down('sm')]: {
      gap: '1rem',
    },
  },

  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    textTransform: 'uppercase',
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(4),
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(3),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
}))

interface AgencySeriesProps {
  showSildeShow?: boolean
  showSubtitle?: boolean
  showEpisode?: boolean
  showButton?: boolean
}

export const AgencySeries: React.FC<AgencySeriesProps> = (props) => {
  const {
    showSildeShow = true,
    showSubtitle = true,
    showEpisode = false,
    showButton = false,
  } = props
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()

  // Utiliser useMediaQuery pour ajuster la taille en fonction de l'écran
  const isXs = useMediaQuery(theme.breakpoints.down('xs'))
  const isSm = useMediaQuery(theme.breakpoints.between('sm', 'md'))
  const isSmDown = useMediaQuery(theme.breakpoints.down('sm'))
  const isMd = useMediaQuery(theme.breakpoints.between('md', 'lg'))
  const isLg = useMediaQuery(theme.breakpoints.up('lg'))

  let size: 'xs' | 'sm' | 'md' | 'lg' = 'md' // Valeur par défaut

  if (isXs) size = 'xs'
  if (isSm) size = 'sm'
  if (isMd) size = 'md'
  if (isLg) size = 'lg'

  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item justifyContent="center" sm={12} style={{ display: 'flex' }}>
        <Image
          src="netflix_group.png"
          alt="Netflix group"
          layout="CONSTRAINED"
          style={{ width: isSmDown ? '50%' : '15%' }}
        />
      </Grid>
      <Grid item justifyContent="center" sm={12}>
        <Grid item sm={12} className={classes.agencyDetailsTitle}>
          {intl.formatMessage({ id: 'new.Agence_15' })}
        </Grid>
        {showSubtitle && (
          <Grid item sm={12} className={classes.sub_title}>
            {intl.formatMessage({ id: 'new.Agence_16' })}
          </Grid>
        )}
        <Hidden mdUp>
          <Grid item md={12} className={clsx(classes.images, classes.imageOverlay)}>
            <Image
              src="L_Agence3.jpg"
              className={classes.img}
              alt="Broooklyn bridge"
              layout="CONSTRAINED"
              style={{ height: '100%', width: '100%', zIndex: 0, borderRadius: '8px' }}
            />
          </Grid>
        </Hidden>
        <Hidden mdDown>
          {showSildeShow && (
            <Grid
              container
              direction="row"
              justifyContent="space-between"
              className={showSubtitle ? '' : classes.topPadding}
            >
              <Grid item md={4} className={clsx(classes.images, classes.imageOverlay)}>
                <Image
                  src="L_Agence3.jpg"
                  className={classes.img}
                  alt="Broooklyn bridge"
                  // layout="CONSTRAINED"
                  style={{
                    aspectRatio: 'calc(345/240)',
                    width: '100%',
                    zIndex: 0,
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item md={4} className={clsx(classes.images, classes.imageOverlay)}>
                <Image
                  src="agence2.png"
                  className={classes.img}
                  alt="Broooklyn bridge"
                  // layout="CONSTRAINED"
                  style={{
                    aspectRatio: 'calc(397/268)',
                    width: '100%',
                    zIndex: 0,
                    borderRadius: '8px',
                  }}
                />
              </Grid>
              <Grid item md={4} className={clsx(classes.images, classes.imageOverlay)}>
                <Image
                  src="agence3.png"
                  className={classes.img}
                  alt="Broooklyn bridge"
                  // layout="CONSTRAINED"
                  style={{
                    aspectRatio: 'calc(397/268)',
                    width: '100%',
                    zIndex: 0,
                    borderRadius: '8px',
                  }}
                />
              </Grid>
            </Grid>
          )}
        </Hidden>
        <Grid xs={12}>
          <Grid item xs={12} className={classes.detail}>
            {intl
              .formatMessage({ id: 'new.Agence_16' })
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>
                  {paragraph.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>
                      {line}
                    </React.Fragment>
                  ))}
                </p>
              ))}
          </Grid>
        </Grid>
        {showEpisode && (
          <>
            <Grid item xs={12} className={classes.episodeTitle}>
              <Box>{intl.formatMessage({ id: 'episode' })}</Box>
            </Grid>
            <Grid item xs={12} className={classes.episode}>
              <Image src="a4.png" alt="buy_banner" layout="FULL_WIDTH" />
            </Grid>
          </>
        )}
        <Grid container md={12} className={classes.circularTextBoxContainer}>
          <CircularText
            topText={intl.formatMessage({ id: 'new.Agence_17' })}
            bottomText={intl.formatMessage({ id: 'new.Agence_18' })}
            centerText={intl.formatMessage({ id: 'new.Agence_19' })}
            size={size}
            textColor="#000"
            borderColor="#000"
          />

          <CircularText
            topText={intl.formatMessage({ id: 'new.Agence_20' })}
            bottomText={intl.formatMessage({ id: 'new.Agence_21' })}
            centerText={intl.formatMessage({ id: 'new.Agence_22' })}
            size={size}
            textColor="#000"
            borderColor="#000"
          />

          <CircularText
            topText={intl.formatMessage({ id: 'new.Agence_20' })}
            bottomText={intl.formatMessage({ id: 'new.Agence_21' })}
            centerText={intl.formatMessage({ id: 'new.Agence_25' })}
            size={size}
            textColor="#000"
            borderColor="#000"
          />
        </Grid>

        {showButton && (
          <Grid item xs={12} className={classes.btnDiv}>
            <CustomButton
              isDarkBackground={true}
              displayIcon={isSm ? false : true}
              iconPosition="end"
              icon={() => <ArrowForwardIcon />}
            >
              <GatsbyLink
                target={'_blank'}
                to={`https://www.netflix.com/fr/title/81417684`}
                color="inherit"
                className={classes.btnLink}
              >
                <Typography className={classes.btnTxt}>
                  {intl.formatMessage({ id: 'agency.btnText' })}
                </Typography>
              </GatsbyLink>
            </CustomButton>
          </Grid>
        )}
      </Grid>
    </Grid>
  )
}

export default AgencySeries
