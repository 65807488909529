import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Typography, useMediaQuery } from '@material-ui/core'
import { Image } from '../../Images'
import { Link as GatsbyLink } from 'gatsby'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import RenderSocialLinks from './RenderSocialLinks'
import { useTheme } from '@material-ui/core/styles'
import { useIntl } from 'gatsby-plugin-intl'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    paddingBlock: '40px',
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '20px 0',
    },
  },
  innerDiv: {
    justifyContent: 'space-between',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
    },
  },
  reverseOrderFirst: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  reverseOrderSecond: {
    order: 2,
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  img: {
    width: '100%',
    aspectRatio: 'calc(456/295)',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  imgPhone: {
    // width: '30%',
    height: '706px',
    aspectRatio: 'calc(350/706)',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      aspectRatio: 'calc(188/379)',
      height: '379px',
      objectFit: 'cover',
    },
  },

  title: {
    fontFamily: "'Gotham Medium', serif",
    lineHeight: '18px',
    letterSpacing: '0em',
    fontSize: '18px',
    fontWeight: 700,
    textTransform: 'uppercase',
    textAlign: 'left',
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: '24px',
      fontSize: theme.typography.pxToRem(18),
    },
  },
  detail: {
    fontFamily: "'Gotham Light', serif",
    lineHeight: '24px',
    fontSize: '14px',
    fontWeight: 325,
    textAlign: 'left',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      flexDirection: 'column',
      alignItems: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  followUs: {
    fontFamily: `'Gotham Medium', serif`,
    display: 'flex',
    fontSize: '14px',
    lineHeight: '24px',
    fontWeight: 700,
  },
  rsBox: {
    display: 'flex',
    flexDirection: 'row',
    gap: '2rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      justifyContent: 'center',
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(13.8),
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(15.6),
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
  },
}))

interface TextImageComponentProps {
  text: {
    title: string | React.ReactNode
    detail: string | React.ReactNode
    buttonText: string
    link: string
  }
  imageName: string
  direction?: 'ltr' | 'rtl'
  showButton?: boolean
  showSocialBtn?: boolean
  isPhoneImage?: boolean
  type?: 'image' | 'video'
  onClickButton?: () => void
}

const TextImageComponent: React.FC<TextImageComponentProps> = ({
  text,
  imageName,
  direction = 'ltr',
  showButton = true,
  showSocialBtn = false,
  isPhoneImage = false,
  type = 'image',
  onClickButton,
}) => {
  const classes = useStyles()
  const theme = useTheme()
  const intl = useIntl()
  const isSMDown = useMediaQuery(theme.breakpoints.down('sm'))

  return (
    <Grid container className={classes.root}>
      <Grid container spacing={isSMDown ? 0 : 10} className={classes.innerDiv}>
        <Grid
          item
          lg={6}
          md={12}
          className={direction === 'rtl' ? classes.reverseOrderSecond : classes.reverseOrderFirst}
        >
          <Grid className={classes.title}>
            <span>{text.title} </span>
          </Grid>
          <Grid container spacing={3} className={classes.detail}>
            <Grid item md={12} lg={12}>
              {text.detail}
            </Grid>
            {showSocialBtn && (
              <Grid item md={12} lg={12} className={classes.rsBox}>
                <Typography className={classes.followUs}>
                  {intl.formatMessage({ id: 'new.followUsOn' })}
                </Typography>
                <RenderSocialLinks />
              </Grid>
            )}
          </Grid>
          {showButton && (
            <Grid item xs={12} md={8} className={classes.btnDiv}>
              <GatsbyLink to={`/${text.link}`} color="inherit" className={classes.btnLink}>
                <Typography className={classes.btnTxt} onClick={onClickButton}>
                  {text.buttonText} <ArrowForwardIcon />
                </Typography>
              </GatsbyLink>
            </Grid>
          )}
        </Grid>
        <Grid
          item
          lg={6}
          xs={12}
          className={direction === 'rtl' ? classes.reverseOrderFirst : classes.reverseOrderSecond}
        >
          {type === 'image' ? (
            <Image
              src={imageName}
              className={isPhoneImage ? classes.imgPhone : classes.img}
              alt="Image"
              style={{ zIndex: 0, borderRadius: '8px' }}
            />
          ) : (
            <video
              width="50%"
              autoPlay
              loop
              muted
              playsInline
              className={isPhoneImage ? classes.imgPhone : classes.img}
            >
              <source src={imageName} type="video/mp4" />
              Votre navigateur ne supporte pas la vidéo.
            </video>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default TextImageComponent
