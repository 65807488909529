import React, { useEffect, useRef } from 'react'
import { graphql, PageProps, useStaticQuery } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import Box from '@material-ui/core/Box'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'

import { Banner } from '../component/Banner'
import { Image } from '../component/Images'
import { Layout } from '../component/Layout'
import { AgencyDetails } from '../component/page_component/AgencyDetails'
import AgencyGallery from '../component/page_component/AgencyGallery'
import AgencyLinks from '../component/page_component/AgencyLinks'
import AgencyTechnology from '../component/page_component/AgencyTechnology'
import AgencyPartner from '../component/page_component/AgencyPartner'
import Experts from '../component/page_component/Experts'
import FriendsOfHouse from '../component/page_component/FriendsOfHouse'
import Intro from '../component/page_component/Intro'
import Review from '../component/page_component/Review'
import Team from '../component/page_component/Team'
import hreflangConfig from '../utils/hreflangConfig'
import { Helmet } from 'react-helmet'
import AgencyIntro from '../component/page_component/agency/AgencyIntro'
import { Typography } from '@material-ui/core'
import AgencySeries from '../component/page_component/agency/AgencySeries'
import OurHistory from '../component/page_component/agency/OurHistory'
import AgencyEvents from '../component/page_component/agency/AgencyEvents'
import { Cinemagraph } from '../component/Cimenegraph'

const useStyles = makeStyles((theme) => ({
  introText: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(50),
    lineHeight: 1,
    fontWeight: 400,
    width: '100%',
    margin: '.8em 0',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(30),
      margin: '.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(25),
      margin: '.3em 0',
    },
  },
  introContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      justifyContent: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      justifyContent: 'center',
    },
  },
  containerTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '80px',
    fontSize: '80px',
    fontWeight: 400,
    textTransform: 'uppercase',
    marginTop: theme.spacing(1.5),
    [theme.breakpoints.between('xs', 'md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(48),
    },
  },
  teamContainer: {
    backgroundColor: '#FAE9E5',
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  friendOfTheHouseContainer: {
    backgroundColor: '#FAE9E5',
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  expertsContainer: {
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(10),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(0),
      // paddingBottom: theme.spacing(4),
    },
  },
  partnersContainer: {
    textAlign: 'center',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
    },
  },
  techContainer: {
    textAlign: 'center',
    paddingBlock: '5rem',
    paddingInline: '204px 5rem',
    backgroundColor: '#FAE9E5',
    [theme.breakpoints.down('lg')]: {
      paddingInline: '100px',
    },
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      paddingInline: '100px ',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingInline: '0px ',
    },
  },
  pictureInsideContainer: {
    textAlign: 'center',
    margin: '2em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '1.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.8em 0',
    },
  },
  introImageFooter: {
    margin: '.8em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.3em 0',
    },
  },
  agencyContainer: {
    textAlign: 'center',
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      // paddingBottom: theme.spacing(6),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      // paddingTop: theme.spacing(2),
      // paddingBottom: theme.spacing(4),
    },
  },
  networkContainer: {
    textAlign: 'center',
    paddingBlock: '5rem',
    paddingInline: '204px',
    paddingTop: theme.spacing(10),
    paddingBottom: theme.spacing(15),
    [theme.breakpoints.between('md', 'md')]: {
      paddingInline: '100px',
    },
    [theme.breakpoints.between('md', 'md')]: {
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(6),
      paddingInline: '3rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(4),
      paddingInline: '0rem',
    },
  },
  introDiv: {
    marginTop: '64px',
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
  img: {
    paddingRight: '1rem',
  },
  imagNew: {},
  gallery: {
    minHeight: '40vh',
    backgroundColor: '#FAE9E5',
  },
  fullWidth: {
    width: '100%',
  },
  description: {
    fontSize: theme.typography.pxToRem(18),
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: 1,
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(14),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(15),
    },
  },
  profile: {
    textAlign: 'center',
    flexBasis: '40%',
    [theme.breakpoints.between('xs', 'sm')]: {
      flexBasis: '60%',
    },
  },
  intro: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
  imageWrapper: {
    position: 'absolute',
    right: '20%',
    [theme.breakpoints.between('md', 'md')]: {},
    [theme.breakpoints.between('xs', 'sm')]: {
      right: '15%',
    },
  },
  icon: {
    width: '15rem',
    height: 'auto',
    [theme.breakpoints.between('md', 'md')]: {
      width: '12rem',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      width: '8rem',
    },
  },
  sliderDiv: {
    [theme.breakpoints.between('xs', 'sm')]: {},
  },
}))

const Agency: React.FC<PageProps> = (props) => {
  const { location } = props
  const intl = useIntl()
  const classes = useStyles()
  const teamRef = useRef<null | HTMLElement>(null)
  const friendRef = useRef<null | HTMLElement>(null)

  useEffect(() => {
    if (window.location.hash) {
      const id = window.location.hash.split('#')[1]
      if (id === 'team' && teamRef && teamRef.current) {
        teamRef.current.scrollIntoView()
      }
      if (id === 'friend' && friendRef && friendRef.current) {
        friendRef.current.scrollIntoView()
      }
    }
  }, [])

  const hreflangLinks = hreflangConfig['/fr/agency/']
  const dataCanonical = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          siteUrl
        }
      }
    }
  `)
  const canonicalUrl = `${dataCanonical.site.siteMetadata.siteUrl}${location.pathname}`
  return (
    <Layout
      showFlotingMenu={false}
      maxWidth={false}
      location={location}
      title={intl.formatMessage({ id: "L'Agence" })}
    >
      <Helmet>
        <link rel="canonical" href={canonicalUrl} />
        {hreflangLinks &&
          Object.keys(hreflangLinks).map((lang) => (
            <link key={lang} rel="alternate" hrefLang={lang} href={hreflangLinks[lang]} />
          ))}
        <meta name="description" content="L'agence - Kretz" />
      </Helmet>
      {/* <Banner
        src="a9.png"
        innerStyle={{
          zIndex: '1100',
        }}
        className={classes.imagNew}
      />
      <AgencyLinks /> */}
      <Grid container justifyContent="center" className={classes.introDiv}>
        <Grid container className={classes.introContainer}>
          <Grid item md={12}>
            <Typography className={classes.containerTitle}>
              {intl.formatMessage({ id: 'new.Agence' })}
            </Typography>
          </Grid>
          <AgencyIntro />
        </Grid>
      </Grid>
      <Grid container className={classes.teamContainer}>
        <Box id="team" className={classes.fullWidth} ref={teamRef}>
          <Team />
        </Box>
      </Grid>
      <Grid container className={classes.expertsContainer}>
        <Box id="experties">
          <Experts />
        </Box>
      </Grid>
      <Grid container className={classes.agencyContainer}>
        <Box id="netflix">
          <AgencySeries showSildeShow={true} showSubtitle={false} />
        </Box>
      </Grid>
      <Grid container className={classes.techContainer}>
        <Box id="history">
          <OurHistory
            text={{
              title: intl.formatMessage({ id: 'new.Agence_26' }),
              detail: intl
                .formatMessage({ id: 'new.Agence_27' })
                .split('\n')
                .map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split('\n').map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>{line}</React.Fragment>
                    ))}
                  </p>
                )),
              buttonText: '',
              link: '',
            }}
            imageName="ah1.png"
          />
        </Box>
      </Grid>
      <Grid container className={classes.networkContainer}>
        <Box id="network">
          <AgencyEvents title={intl.formatMessage({ id: 'new.Agence_28' })} />
        </Box>
      </Grid>
      <Cinemagraph flixelId="jtsueywkv381kalwb9l5" flixelMobileId="7od2xokpjecfjrq2jgsh" />
    </Layout>
  )
}

export default Agency
