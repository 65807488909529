import React, { useEffect, useRef, useState } from 'react'

import { useIntl } from 'gatsby-plugin-intl'
import Grid from '@material-ui/core/Grid'
import Box from '@material-ui/core/Box'
import { makeStyles } from '@material-ui/core/styles'
import Accordion from '@material-ui/core/Accordion'
import AccordionDetails from '@material-ui/core/AccordionDetails'
import AccordionSummary from '@material-ui/core/AccordionSummary'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Avatar } from '../Avatar'
import AgentContactViaEmail from './AgenctContactViaEmail'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import { useTheme } from '@material-ui/core/styles'
import useWindowSize from '../../utils/useWindowSize'
import { graphql, useStaticQuery } from 'gatsby'

import { capitalize } from '../../utils/helper'
import { CustomButton } from '../CustomButton'
import Carousel from 'react-multi-carousel'
import CustomButtonGroupAsArrows from './Destination/CustomButtonGroupArrows'
import theme from './../../Theme/theme'
import GatsbyLink from 'gatsby-link'

const useStyles = makeStyles((theme) => ({
  teamTitle: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(130),
    textAlign: 'center',
    textTransform: 'uppercase',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(80),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: '48px',
      fontWeight: 400,
      lineHeight: '48px',
      marginBottom: '40px',
    },
  },
  subtitle: {
    width: '100%',
    flexBasis: '80%',
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(24),
    textAlign: 'center',
    lineHeight: '32px',
    paddingBlock: '2rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
      marginBottom: '40px',
    },
  },
  preSlider: {
    width: '100%',
    fontFamily: `'Gotham Medium', serif`,
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    lineHeight: '32px',
    fontWeight: 700,
    textTransform: 'uppercase',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginBottom: '40px',
    },
  },
  postSlider: {
    width: '90%',
    fontFamily: `'Gotham Medium', serif`,
    fontSize: theme.typography.pxToRem(14),
    textAlign: 'center',
    lineHeight: '32px',
    fontWeight: 700,
    textTransform: 'uppercase',
  },
  footer: {
    width: '100%',
    flexBasis: '50%',
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: theme.typography.pxToRem(24),
    textAlign: 'center',
    lineHeight: '32px',
    fontWeight: 400,
    paddingBlock: '2rem 2rem',
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'none',
    },
  },
  btnBox: {
    flexBasis: '50%',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: '24px',
    },
  },
  btnLink: {
    fontFamily: `'Gotham Book', serif`,
    fontSize: theme.typography.pxToRem(12),
    lineHeight: '12px',
    textAlign: 'center',
    fontWeight: 700,
    textTransform: 'uppercase',
    textDecoration: 'none',
    color: '#1d1d1b',
    '&:hover': {
      color: '#1d1d1b',
    },
  },
  root: {
    width: '100%',
    margin: '5em 0',
    [theme.breakpoints.between('md', 'md')]: {
      margin: '1.5em 0',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      margin: '.8em 0',
    },
  },
  accordionSummary: {
    background: 'transparent',
    borderTop: `${theme.typography.pxToRem(1)} solid #b7b0b0`,
    boxShadow: 'none',
    borderRadius: 'unset',
  },
  heading: {
    fontFamily: `'Gotham Medium', serif`,
    fontSize: theme.typography.pxToRem(25),
    textAlign: 'center',
    textTransform: 'uppercase',
    width: '100%',
    lineHeight: 1.2,
    letterSpacing: '0.02em',
    [theme.breakpoints.between('md', 'md')]: {
      fontSize: theme.typography.pxToRem(18),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(18),
    },
  },
  accordionDetails: {
    borderTop: `${theme.typography.pxToRem(1)} solid #b7b0b0`,
  },
  teamContainer: {
    width: '100%',
    paddingBlock: '80px',
  },
}))

type ProfileType = {
  src: string
  name: string
  phone: string
  isPhoneDisplayed: boolean
  email: string
  designation: string
  about?: string
  photo: {
    portraitSquare: string
  }
}

interface DetailsProps {
  profiles: ProfileType[]
}

const Team = () => {
  const intl = useIntl()
  const classes = useStyles()
  const matches = useMediaQuery(theme.breakpoints.down('md'))

  // const handleClick = () => {
  //   const redirectUrl = `https://www.join-kretz.com/`
  //   window.open(redirectUrl, '_blank')
  // }

  const {
    allTeam: { edges },
  } = useStaticQuery(graphql`
    query MyQuery {
      allTeam {
        edges {
          node {
            id
            lname
            fname
            phone
            email
            photo {
              portraitSquare
            }
            destination

            descriptionEn
            descriptionEs
            descriptionFr
            descriptionPt

            titreEn
            titreEs
            titreFr
            titrePt
          }
        }
      }
    }
  `)

  const smallArray = [
    { Ordre: 1, Prénom: 'Olivier', Nom: 'Kretz', Description: 'Olivier Kretz - Fondateur et CEO' },
    {
      Ordre: 2,
      Prénom: 'Sandrine',
      Nom: 'Kretz',
      Description: 'Sandrine Kretz - Directrice associée',
    },

    { Ordre: 3, Prénom: 'Martin', Nom: 'Kretz', Description: 'Martin Kretz - Directeur associé' },
    {
      Ordre: 4,
      Prénom: 'Valentin',
      Nom: 'Kretz',
      Description: 'Valentin Kretz - Directeur associé',
    },
    {
      Ordre: 5,
      Prénom: 'Louis',
      Nom: 'Kretz',
      Description: 'Louis Kretz - Co-directeur Kretz spain',
    },
    {
      Ordre: 6,
      Prénom: 'Alexandre',
      Nom: 'Bruneau',
      Description: 'Alexandre Bruneau - Directeur Général',
    },

    {
      Ordre: 7,
      Prénom: 'Adriana',
      Nom: 'Pinos',
      Description: 'Adriana Pinos -  Co-directrice Kretz Spain',
    },
    {
      Ordre: 8,
      Prénom: 'Camille',
      Nom: 'Richard',
      Description: 'Camille Richard - Coordinateur Commerciale Kretz Spain',
    },
    { Ordre: 9, Prénom: 'Yann', Nom: 'Miossec', Description: 'Yann Miossec - Directeur Financier' },
    {
      Ordre: 10,
      Prénom: 'Victor',
      Nom: 'Choppe',
      Description: 'Victor Choppe - Responsable Animation Commerciale',
    },
    {
      Ordre: 11,
      Prénom: 'Virgile',
      Nom: 'Voiseau',
      Description: 'Virgile Voiseau - Responsable Production de contenu',
    },
    {
      Ordre: 12,
      Prénom: 'Lisa',
      Nom: 'Azzarone',
      Description: 'Lisa Azzarone - Responsable Communication',
    },
    {
      Ordre: 13,
      Prénom: 'Mathilde',
      Nom: 'Mas',
      Description: 'Mathilde Mas - Chargée de communication',
    },
    {
      Ordre: 14,
      Prénom: 'Melgwen',
      Nom: 'Dosseh-Martenot',
      Description: 'Melgwen Dosseh-Martenot - Responsable Coordination Commerciale',
    },
    {
      Ordre: 15,
      Prénom: 'Amandine',
      Nom: 'Landois',
      Description: 'Amandine Landois - Coordinatrice Commerciale',
    },
    {
      Ordre: 16,
      Prénom: 'Victoire',
      Nom: "Parra d'Andert",
      Description: 'Victoire Parra d’Andert - Coordinatrice Commerciale',
    },
    {
      Ordre: 17,
      Prénom: 'Raphaël',
      Nom: 'Kretz',
      Description: '',
    },
  ]

  const teamProfiles = edges
    ?.filter((person: any) =>
      smallArray.some(
        (smallPerson) =>
          smallPerson.Nom.toLowerCase() === person.node.lname.toLowerCase() &&
          smallPerson.Prénom.toLowerCase() === person.node.fname.toLowerCase(),
      ),
    )
    .sort((a: any, b: any) => {
      return (
        smallArray.findIndex(
          (smallPerson) =>
            smallPerson.Nom.toLowerCase() === a.node.lname.toLowerCase() &&
            smallPerson.Prénom.toLowerCase() === a.node.fname.toLowerCase(),
        ) -
        smallArray.findIndex(
          (smallPerson) =>
            smallPerson.Nom.toLowerCase() === b.node.lname.toLowerCase() &&
            smallPerson.Prénom.toLowerCase() === b.node.fname.toLowerCase(),
        )
      )
    })

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
      partialVisibilityGutter: 100,
    },
    desktop: {
      breakpoint: { max: 3000, min: 960 },
      items: 4,
      partialVisibilityGutter: 100,
    },
    tablet: {
      breakpoint: { max: 960, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  }

  const weight = {
    DDR_PARIS: 1,
    DDR_USA: 2,
    DDR_ESPAGNE: 3,
    DDR_PORTUGAL: 4,
    DDR_BALI: 5,
    DDR_GRECE: 6,
    DDR_LONDON: 7,
    DDR_NORMANDIE: 8,
    DDR_OCCITANIE: 9,
    DDR_ATLANTIQUE: 10,
    DDR_MEDITERRANEE: 11,
    DDR_MONTAGNE: 12,
    DDR_ILES: 13,
    DDR_BRETAGNES: 14,
    DDR_BORDEAUX: 15,
    DDR_BASQUE: 16,
    DDR_CORSE: 17,
    DDR_LYON: 18,
    DDR_CENTREVDL: 19,
    DDR_INTERNATIONAL: 20,
    DDR_MONACO: 21,
    DDR_GRANDEST: 22,
    DDR_HAUTDEFRANCE: 23,
    A_: 24,
  }

  return (
    <Grid container justifyContent="center" className={classes.teamContainer}>
      <div id={'team-anchor'} style={{ position: 'absolute', left: 0 }}></div>
      <Grid item xs={1} md={2} lg={2} />
      <Grid item xs={12} md={8} lg={8}>
        <Grid container justifyContent="center">
          <Box className={classes.teamTitle}>{intl.formatMessage({ id: 'new.Agence_3' })}</Box>
          <Box className={classes.subtitle}>
            {intl
              .formatMessage({ id: 'new.Agence_4' })
              .split('\n')
              .map((paragraph, index) => (
                <p key={index}>
                  {paragraph.split('\n').map((line, lineIndex) => (
                    <React.Fragment key={lineIndex}>{line}</React.Fragment>
                  ))}
                </p>
              ))}
          </Box>
          <Box className={classes.preSlider}>{intl.formatMessage({ id: 'new.Agence_5' })}</Box>
          <div className={classes.root}>
            <Carousel
              responsive={responsive}
              additionalTransfrom={0}
              arrows={false}
              autoPlaySpeed={5000}
              containerClass="container"
              focusOnSelect={false}
              infinite
              centerMode={matches}
              keyBoardControl
              renderButtonGroupOutside={!matches}
              renderDotsOutside={false}
              customButtonGroup={<CustomButtonGroupAsArrows sideButton={true} />}
            >
              {teamProfiles.map((profile) => (
                <Avatar agent={profile} hideNumber />
              ))}
            </Carousel>
          </div>
          <Box className={classes.postSlider}>{intl.formatMessage({ id: 'new.Agence_6' })}</Box>
          <Box className={classes.footer}>{intl.formatMessage({ id: 'new.Agence_7' })}</Box>
          <Box style={{ width: '100%' }}>
            <Box className={classes.btnBox}>
              <CustomButton isTransparentBackground>
                <GatsbyLink
                  to={`/${intl.locale}/AgencyTeam`}
                  color="inherit"
                  className={classes.btnLink}
                >
                  <Typography>Trouvez votre agent local</Typography>
                </GatsbyLink>
              </CustomButton>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid item xs={1} md={2} lg={2} />
    </Grid>
  )
}

export default Team
