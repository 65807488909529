import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import React, { useRef } from 'react'

import { Link as GatsbyLink } from 'gatsby'
import { useIntl } from 'gatsby-plugin-intl'
import { Image } from '../../Images'

import { Typography } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import { BackgroundImage } from '../../shared/components/BackgroundImage'

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    paddingBlock: '40px 0',
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.between('sm', 'md')]: {
      padding: '1em 2em',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '16px',
    },
  },
  innerDiv: {
    flexBasis: '80%',
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
    },
  },
  reverseOrderFirst: {
    order: 2,
    display: 'flex',
    justifyContent: 'flex-start',
    textAlign: 'left',
    [theme.breakpoints.down('sm')]: {
      order: 1,
    },
  },
  reverseOrderSecond: {
    order: 1,
    [theme.breakpoints.down('sm')]: {
      order: 2,
    },
  },
  img: {
    width: '70%',
    aspectRatio: 'calc(409/536)',
    objectFit: 'cover',
    objectPosition: 'center top',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      width: '100%',
      aspectRatio: 'calc(315/265)',
    },
  },
  imgContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  detail: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '32px',
    fontSize: '24px',
    fontWeight: 400,
    marginTop: theme.spacing(1.5),
    textAlign: 'left',
    [theme.breakpoints.between('md', 'md')]: {
      textAlign: 'center',
      justifyContent: 'center',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      fontSize: '16px',
      fontWeight: 500,
      lineHeight: '28px',
    },
  },
  btnTxt: {
    fontFamily: `'Gotham Book', serif`,
    textTransform: 'uppercase',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 700,
    fontSize: theme.typography.pxToRem(13.8),
    [theme.breakpoints.between('xs', 'md')]: {
      fontSize: theme.typography.pxToRem(10),
    },
    color: 'inherit',
  },
  btnLink: {
    textDecoration: 'none',
    textTransform: 'initial',
    color: 'inherit',
    '&:hover': {
      textDecoration: 'none',
      borderBottomColor: theme.palette.text.primary,
    },
  },
  btnDiv: {
    marginTop: theme.spacing(15.6),
    [theme.breakpoints.between('xs', 'sm')]: {
      display: 'flex',
      justifyContent: 'center',
      marginTop: theme.spacing(4),
    },
  },
}))

export const AgencyIntro = () => {
  const classes = useStyles()
  const intl = useIntl()
  const ref: any = useRef()

  return (
    <Grid container className={classes.root}>
      <Grid container className={classes.innerDiv}>
        <Grid item lg={6} xs={12} className={classes.imgContainer}>
          <Image
            src="DSC08061.jpg"
            className={classes.img}
            alt="l'agence"
            layout="CONSTRAINED"
            style={{
              zIndex: 0,
              borderRadius: '8px',
              objectFit: 'cover',
              objectPosition: 'center top',
            }}
          />
        </Grid>
        <Grid item lg={6} xs={12}>
          <Grid container className={classes.detail}>
            <Grid item>
              {' '}
              {intl
                .formatMessage({ id: 'new.Agence_2' })
                .split('\n')
                .map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split('\n').map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>
                        {line}
                      </React.Fragment>
                    ))}
                  </p>
                ))}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgencyIntro
