import React from 'react'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import { Hidden, Typography, useMediaQuery, useTheme } from '@material-ui/core'
import { Image } from '../../Images'
import { Link as GatsbyLink } from 'gatsby'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import RenderSocialLinks from './RenderSocialLinks'
import { CustomButton } from '../../CustomButton'
import { useIntl } from 'gatsby-plugin-intl'
import videofile from '../../../videos/seq02_white.mp4'

const useStyles = makeStyles((theme) => ({
  root: {
    [theme.breakpoints.between('xs', 'sm')]: {
      padding: '4em 2em',
    },
  },
  innerDiv: {
    justifyContent: 'space-between',
    [theme.breakpoints.only('lg')]: {
      // gap: '5rem',
    },
    [theme.breakpoints.between('xs', 'md')]: {
      flexBasis: 'unset',
      display: 'flex',
      justifyContent: 'center',
      paddingTop: '0px',
      gap: '2rem',
    },
  },
  img: {
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
    },
  },
  imgNew: {
    // height: '80%',
    width: '70%',
    aspectRatio: 'calc(350/706)',
    flexGrow: 0,
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(0),
      aspectRatio: 'calc(188/379)',
      height: '379px',
      width: '188px',
      objectFit: 'cover',
    },
  },

  title: {
    fontFamily: `'Cormorant Garamond', serif`,
    textTransform: 'uppercase',
    lineHeight: '80px',
    fontSize: '80px',
    fontWeight: 400,
    textAlign: 'center',
    marginBottom: '80px',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(48),
      lineHeight: theme.typography.pxToRem(48),
      marginBottom: '40px',
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      marginTop: theme.spacing(1),
      fontSize: theme.typography.pxToRem(48),
      lineHeight: theme.typography.pxToRem(48),
      marginBottom: '40px',
    },
  },
  detail: {
    textAlign: 'left',
    [theme.breakpoints.between('md', 'md')]: {
      marginTop: theme.spacing(0.5),
    },
    [theme.breakpoints.between('xs', 'sm')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
      justifyContent: 'center',
      marginTop: theme.spacing(0.5),
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  imgDetail: {
    [theme.breakpoints.down('md')]: {
      display: 'none',
    },
  },
  textDetail: {
    fontFamily: `'Cormorant Garamond', serif`,
    lineHeight: '32px',
    fontSize: '24px',
    fontWeight: 400,
    textAlign: 'left',
    [theme.breakpoints.down('md')]: {
      textAlign: 'center',
      fontSize: theme.typography.pxToRem(14),
      lineHeight: '24px',
      fontWeight: 325,
      justifyContent: 'center',
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  leftBox: {
    width: '20%',
    [theme.breakpoints.down('lg')]: {
      // flexBasis: '38%',
      padding: '0px',
      paddingTop: '0px',
    },
    [theme.breakpoints.down('md')]: {
      // flexBasis: '60%',
      width: '100%',
      padding: '0px',
      paddingTop: '0px',
    },
  },
  rightBox: {
    display: 'flex',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
    },
  },
}))
const handleClick = () => {
  const redirectUrl = `https://www.join-kretz.com/`
  window.open(redirectUrl, '_blank')
}

interface AgencyEventsProps {
  title: string
}

const AgencyEvents: React.FC<AgencyEventsProps> = ({ title }) => {
  const classes = useStyles()
  const intl = useIntl()
  const theme = useTheme()

  return (
    <Grid container className={classes.root}>
      <Grid xs={12} className={classes.title}>
        <span>{title}</span>
      </Grid>
      <Grid container className={classes.innerDiv}>
        <Grid item lg={6} md={12} className={classes.leftBox}>
          {/* <Image
            src="a99v2.jpg"
            className={classes.imgNew}
            alt="Image"
            style={{ zIndex: 0, borderRadius: '8px' }}
          /> */}
          <video width="50%" autoPlay loop muted playsInline className={classes.imgNew}>
            <source src={videofile} type="video/mp4" />
            Votre navigateur ne supporte pas la vidéo.
          </video>
        </Grid>

        <Grid item lg={6} xs={12} className={classes.rightBox}>
          <Grid container className={classes.detail}>
            <Grid item md={12} lg={12} className={classes.imgDetail}>
              <Image
                src="agence2.png"
                className={classes.img}
                alt="Image"
                layout="CONSTRAINED"
                style={{ height: '100%', width: '100%', zIndex: 0, borderRadius: '8px' }}
              />
            </Grid>
            <Grid item md={12} lg={12} className={classes.textDetail}>
              {intl
                .formatMessage({ id: 'new.Agence_29' })
                .split('\n')
                .map((paragraph, index) => (
                  <p key={index}>
                    {paragraph.split('\n').map((line, lineIndex) => (
                      <React.Fragment key={lineIndex}>{line}</React.Fragment>
                    ))}
                  </p>
                ))}
            </Grid>
            <Hidden smDown>
              <Grid item md={12} lg={12}>
                <CustomButton color={'#000'} isWhiteBackground onClick={handleClick}>
                  {intl.formatMessage({ id: 'new.Agence_30' })}
                </CustomButton>
              </Grid>
            </Hidden>
          </Grid>
          <Hidden smUp>
            <Grid item md={12} lg={12}>
              <CustomButton width={'100%'} color={'#000'} isWhiteBackground onClick={handleClick}>
                {intl.formatMessage({ id: 'new.Agence_30' })}
              </CustomButton>
            </Grid>
          </Hidden>
        </Grid>
      </Grid>
    </Grid>
  )
}

export default AgencyEvents
